import React from 'react';

const App = () => {
  return <div style={{textAlign: 'center'}}>
    <svg width="100" height="100" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0012 0L10.0251 4.61873V13.9765L7.66449 8.69677L5.56009 13.6128L8.1054 19.2956L4.1923 16.813L2.65795 20.4043L6.43066 22.8028H1.63411L0 26.6185H10.0247V32H13.9737V26.6185H24.0012L22.3671 22.8028H17.5742L21.3453 20.4043L19.8109 16.813L15.8978 19.2956L18.4423 13.6128L16.3387 8.69677L13.9757 13.9765V4.61873L12.0012 0Z" fill="#78be20" />
    </svg>
    <h2>Palvelu on poistunut käytöstä.</h2>
    <h2>The service is no longer available.</h2>
  </div>;
};

export default App;
